<template>
  <div>
    <v-data-table dense sort-by="name" :headers="headers" :itemsPerPage="Category.length" :expanded.sync="expanded"
      show-expand :loading="isLoading" hide-default-footer :items="Category">
      <template v-slot:item="{ item, isExpanded, expand }">
        <tr v-if="item.totalPrice - item.totalCost !== 0">
          <td class="text-left">
            <v-btn class="no-print" @click="expand(true)" icon v-if="!isExpanded">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn class="no-print" @click="expand(false)" icon v-if="isExpanded">
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </td>
          <td class="text-left text-capitalize">
            {{ item.name }}
          </td>
          <td class="text-center">
            {{ item.totalQty }} - <span class="caption pink--text">{{ (item.totalQty / totalReportQty * 100).toFixed(2) }}%</span>
          </td>
          <td class="text-right">
            {{ item.totalPrice | currency }}
          </td>
          <td class="text-right" v-if="!hideCost">
            {{ item.totalCost | currency }}
          </td>
          <td v-if="!hideProfit" class="text-right" :style="{ color: color(item) }">
              {{item.totalProfit | currency }} / <span class="green--text">{{ (item.totalProfit /
                item.totalCost * 100).toFixed(2)
            }}%</span>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <v-card color="#F3F8FC" flat>
            <v-card-text class="title">
              <Product :CategoryId="item.id" :hideProfit="hideProfit" :hideCost="hideCost" :start="start" :end="end"
                :CustomerId="CustomerId"></Product>
            </v-card-text>
          </v-card>
        </td>
      </template>
      <template v-slot:body.append>
        <tr :style="{ 'background-color': $style.listTotal.filter }">
          <td></td>
          <td class="font-weight-bold">Total</td>
          <td class="text-right font-weight-bold text-center">{{ totalReportQty }} - <span class="caption pink--text">100%</span></td>
          <td class="text-right font-weight-bold">
            {{ totalReportSale | currency }}
          </td>
          <td v-if="!hideCost" class="text-right font-weight-bold">
            {{ totalReportCost | currency }}
          </td>
          <td v-if="!hideProfit" class="text-right font-weight-bold">
            {{ totalProfit | currency }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import saleService from "@/modules/ReportView/service.js";
const Product = () => import("@/modules/ReportView/GlobalReport/Product.vue");
export default {
  props: ["start", "reset", "end", "CustomerId", "hideProfit", "hideCost"],
  name: "category-profit",
  data() {
    return {
      isLoading: true,
      sortBy: "name",
      Category: [],
      expanded: [],
      expenseExpanded: [],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "QTY",
          align: "center",
          value: "totalQty",
          sortable: true,
        },
        {
          text: "SALE",
          align: "right",
          value: "totalPrice",
          sortable: true,
        },
      ],
      totalReportQty: 0,
      totalReportSale: 0,
      totalReportCost: 0,
    };
  },
  components: {
    Product,
  },
  watch: {
    reset() {
      this.getSaleByCategory();
    },
    CustomerId() {
      this.getSaleByCategory();
    },
  },
  async created() {
    await this.getSaleByCategory();
    if (!this.hideCost) {
      this.headers.push({
        text: "COST",
        align: "right",
        value: "totalCost",
        sortable: true,
      });
    }
    if (!this.hideProfit) {
      this.headers.push({
        text: "PROFIT",
        align: "right",
        value: "totalProfit",
        sortable: true,
      });
    }
  },
  computed: {
    totalProfit() {
      return this.totalReportSale - this.totalReportCost;
    },
  },
  methods: {
    color(item) {
      let value = "black";
      let profit = item.totalPrice - item.totalCost;
      if (profit < 0) {
        value = "red";
      }
      return value;
    },
    getSaleByCategory() {
      // console.log('start', this.start)
      // console.log('end', this.end)
      let where = {};
      if (this.start) where.start = this.start;
      if (this.end) where.end = this.end;
      if (this.CustomerId) where.CustomerId = this.CustomerId;

      return saleService.getSaleByCategory(where).then((response) => {
        this.Category = response.data;
        // // console.log('category', this.Category)
        this.Category.map((row) => {
          if (row.totalQty) {
            this.totalReportQty += parseInt(row.totalQty)
          }
          this.totalReportSale += row.totalPrice;
          this.totalReportCost += row.totalCost;
          let totalSale = this.totalReportSale - this.totalReportCost;
          this.$emit("fatchData", totalSale);
        });
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
</style>
